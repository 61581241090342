// Generated by Framer (3fa6aa4)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import MenuTab from "./ie2BJKSUY";
const MenuTabFonts = getFonts(MenuTab);

const cycleOrder = ["dH2C1dJ1Q", "S9MAa5q5B"];

const serializationHash = "framer-HS5zU"

const variantClassNames = {dH2C1dJ1Q: "framer-v-1194kg3", S9MAa5q5B: "framer-v-1lcmowd"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Desktop: "dH2C1dJ1Q", Tablet: "S9MAa5q5B"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "dH2C1dJ1Q"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "dH2C1dJ1Q", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1194kg3", className, classNames)} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"dH2C1dJ1Q"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({S9MAa5q5B: {"data-framer-name": "Tablet"}}, baseVariant, gestureVariant)}><ComponentViewportProvider ><motion.div className={"framer-15kxdf6-container"} data-framer-name={"Twitter"} layoutDependency={layoutDependency} layoutId={"AGoY0jHwp-container"} name={"Twitter"}><MenuTab fLs7hu8ue={"TwitterLogo"} height={"100%"} id={"AGoY0jHwp"} layoutId={"AGoY0jHwp"} name={"Twitter"} PKa6l2cEL={"https://x.com/ic3rocks"} ubOQCqHJ_={"Home"} variant={"E4JNYRnH5"} width={"100%"}/></motion.div></ComponentViewportProvider><ComponentViewportProvider ><motion.div className={"framer-pywkxu-container"} data-framer-name={"Framer"} layoutDependency={layoutDependency} layoutId={"T1PWkwX69-container"} name={"Framer"}><MenuTab fLs7hu8ue={"TelegramLogo"} height={"100%"} id={"T1PWkwX69"} layoutId={"T1PWkwX69"} name={"Framer"} PKa6l2cEL={"https://t.me/ic3yspicy"} ubOQCqHJ_={"Home"} variant={"E4JNYRnH5"} width={"100%"}/></motion.div></ComponentViewportProvider><ComponentViewportProvider ><motion.div className={"framer-azdy7d-container"} data-framer-name={"Instagram"} layoutDependency={layoutDependency} layoutId={"YO3BpJt2v-container"} name={"Instagram"}><MenuTab fLs7hu8ue={"DiscordLogo"} height={"100%"} id={"YO3BpJt2v"} layoutId={"YO3BpJt2v"} name={"Instagram"} PKa6l2cEL={"discord://-/users/244188272547332097"} ubOQCqHJ_={"Home"} variant={"E4JNYRnH5"} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-HS5zU.framer-k6xklq, .framer-HS5zU .framer-k6xklq { display: block; }", ".framer-HS5zU.framer-1194kg3 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; height: min-content; justify-content: space-between; overflow: visible; padding: 0px; position: relative; width: 192px; }", ".framer-HS5zU .framer-15kxdf6-container, .framer-HS5zU .framer-pywkxu-container, .framer-HS5zU .framer-azdy7d-container { flex: none; height: auto; position: relative; width: auto; }", ".framer-HS5zU.framer-v-1lcmowd.framer-1194kg3 { flex-direction: column; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-HS5zU.framer-v-1lcmowd.framer-1194kg3 { gap: 0px; } .framer-HS5zU.framer-v-1lcmowd.framer-1194kg3 > *, .framer-HS5zU.framer-v-1lcmowd.framer-1194kg3 > :first-child, .framer-HS5zU.framer-v-1lcmowd.framer-1194kg3 > :last-child { margin: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 48
 * @framerIntrinsicWidth 192
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"S9MAa5q5B":{"layout":["fixed","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerFM41FH4jh: React.ComponentType<Props> = withCSS(Component, css, "framer-HS5zU") as typeof Component;
export default FramerFM41FH4jh;

FramerFM41FH4jh.displayName = "Social Menu";

FramerFM41FH4jh.defaultProps = {height: 48, width: 192};

addPropertyControls(FramerFM41FH4jh, {variant: {options: ["dH2C1dJ1Q", "S9MAa5q5B"], optionTitles: ["Desktop", "Tablet"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramerFM41FH4jh, [{explicitInter: true, fonts: []}, ...MenuTabFonts], {supportsExplicitInterCodegen: true})